const PreventScroll = () => {
  document.body.style.overflowY = "hidden";
  document.body.style.position = "static";
  return () => {
    document.body.style.overflowY = "scroll";
    document.body.style.position = "inherit";
  };
};

export default PreventScroll;

import React, { useState } from "react";
import { motion } from "framer-motion";
import { animDesc, animImg, animShape, animHeader } from "./motions";
import { Parallax } from "react-scroll-parallax";
import master from "../images/master.svg";
import MasterCard from "../images/1_MasterCard.png";
import sosy from "../images/sosy.jpg";
import tao from "../images/tao.svg";
import arte from "../images/arte.png";
import doki4 from "../images/doki4.png";
import wakacje4 from "../images/wakacje4.png";
import dot from "../images/dot.svg";
import vifon from "../images/vifon.jpg";
import prazynki from "../images/vifon-prazynki.png";
import busstacja from "../images/solaris-busstacja.png";
import cover from "../images/cover.svg";
import mitsubishiMelf from "../images/mitsubishi-melf.jpg";
import vifonCieszyMiche from "../images/vifon_cieszy_miche.jpg";
import betclicStudios from "../images/betclic_studios.jpg";
import avia from "../images/avia_płatności_spark.jpg";
import "../styles/1.scss";

const Home1 = ({ setShowCampaign, setCampaignData, paralaxSpeed }) => {
  const [show, setShow] = useState(false);
  const [urls, setUrls] = useState([]);
  const [names, setNames] = useState();

  const [s1animation, setS1Animation] = useState(false);
  const [s2animation, setS2Animation] = useState(false);
  const [s3animation, setS3Animation] = useState(false);
  const [s4animation, setS4Animation] = useState(false);
  const [s5animation, setS5Animation] = useState(false);
  const [s6animation, setS6Animation] = useState(false);
  const [s7animation, setS7Animation] = useState(false);
  const [s8animation, setS8Animation] = useState(false);
  const [s9animation, setS9Animation] = useState(false);
  const [s10animation, setS10Animation] = useState(false);
  const [s11animation, setS11Animation] = useState(false);
  const [s12animation, setS12Animation] = useState(false);

  return (
    <div className="home1">
      {/* SECTION 1 */}

      <div
        className="section home1__section1"
        onClick={() => {
          setCampaignData("mastercard");
          setShowCampaign(true);
        }}
        id="mastercard"
      >
        <div className="section-desc home1__section1-left">
          <Parallax speed={paralaxSpeed}>
            <motion.div
              className="image-desc_right"
              {...animDesc}
              animate={s1animation ? "hover" : "initial"}
              onMouseEnter={() => {
                setS1Animation(true);
              }}
              onMouseLeave={() => {
                setS1Animation(false);
              }}
            >
              <div className="line"></div>
              <h3>Mastercard</h3>
              <p>Spełnienie</p>
            </motion.div>
          </Parallax>
        </div>
        <div className="section-image home1__section1-right">
          <motion.img
            src={MasterCard}
            alt="mastercard"
            {...animImg}
            animate={s1animation ? "hover" : "initial"}
            onMouseEnter={() => {
              setS1Animation(true);
            }}
            onMouseLeave={() => {
              setS1Animation(false);
            }}
          />
          <Parallax
            speed={paralaxSpeed}
            className="home1__section1-right-circle"
          >
            <motion.img
              src={master}
              alt="mastercard"
              className=" color-shape"
              {...animShape}
              animate={s1animation ? "hover" : "initial"}
            />
          </Parallax>
        </div>
      </div>
      {/* SECTION 2 */}

      <div
        className="section home1__section2"
        onClick={() => {
          setCampaignData("mitsubishi-electric");
          setShowCampaign(true);
        }}
        id="mitsubishi-electric"
      >
        <div className="show-desktop home1__section2-left">
          <motion.img
            src={mitsubishiMelf}
            className="home1__section2-left-main"
            {...animImg}
            animate={s2animation ? "hover" : "initial"}
            onMouseEnter={() => {
              setS2Animation(true);
            }}
            onMouseLeave={() => {
              setS2Animation(false);
            }}
          />
        </div>
        <div className="section home1__section2-right">
          <Parallax
            speed={paralaxSpeed}
            className="section-header home1__section2-right-title"
          >
            <div className="header-index">
              <motion.h2
                {...animHeader}
                animate={s2animation ? "hover" : "initial"}
              >
                WE ARE FUTURE
              </motion.h2>
            </div>
          </Parallax>
          <div className="section-image show-mobile">
            <motion.img
              src={mitsubishiMelf}
              className="home1__section2-left-main"
              {...animImg}
              animate={s2animation ? "hover" : "initial"}
              onMouseEnter={() => {
                setS2Animation(true);
              }}
              onMouseLeave={() => {
                setS2Animation(false);
              }}
            />
          </div>
          <Parallax
            speed={paralaxSpeed}
            className="section-desc home1__section2-right-content"
          >
            <motion.div
              className="image-desc"
              {...animDesc}
              animate={s2animation ? "hover" : "initial"}
              onMouseEnter={() => {
                setS2Animation(true);
              }}
              onMouseLeave={() => {
                setS2Animation(false);
              }}
            >
              <div className="line"></div>
              <h3>Mitsubishi Electric</h3>
              <p>Melf</p>
            </motion.div>
          </Parallax>
        </div>
      </div>

      {/* SECTION 3 */}
      <div
        className="section home1__section3"
        onClick={() => {
          setCampaignData("betclic");
          setShowCampaign(true);
        }}
        id="betclic"
      >
        <div className="section home1__section3-left">
          <Parallax speed={paralaxSpeed} className="section-header">
            <div className="header-index">
              <motion.h2
                className="relax"
                {...animHeader}
                animate={s3animation ? "hover" : "initial"}
              >
                WE ARE DOCUMENTARY
              </motion.h2>
            </div>
          </Parallax>
          <div className="section-image show-mobile image-container home1__section3-img">
            <motion.img
              src={betclicStudios}
              {...animImg}
              animate={s3animation ? "hover" : "initial"}
              onMouseEnter={() => {
                setS3Animation(true);
              }}
              onMouseLeave={() => {
                setS3Animation(false);
              }}
            />
          </div>
          <Parallax
            speed={paralaxSpeed}
            className="section-desc home1__section3-bottom"
          >
            <motion.div
              className="image-desc_right"
              {...animDesc}
              animate={s3animation ? "hover" : "initial"}
              onMouseEnter={() => {
                setS3Animation(true);
              }}
              onMouseLeave={() => {
                setS3Animation(false);
              }}
            >
              <div className="line"></div>
              <h3>Betclic</h3>
              <p>Wybierz swój kurs</p>
            </motion.div>
          </Parallax>
        </div>

        <div className="show-desktop image-container home1__section3-img">
          <motion.img
            src={betclicStudios}
            {...animImg}
            animate={s3animation ? "hover" : "initial"}
            onMouseEnter={() => {
              setS3Animation(true);
            }}
            onMouseLeave={() => {
              setS3Animation(false);
            }}
          />
        </div>
      </div>

      {/* SECTION 4 */}
      <div
        className="section home1__section4"
        onClick={() => {
          setCampaignData("vifon-cieszy-miche");
          setShowCampaign(true);
        }}
        id="vifon-cieszy-miche"
      >
        <div className="section-image home1__section4-left">
          <motion.img
            src={vifonCieszyMiche}
            {...animImg}
            animate={s4animation ? "hover" : "initial"}
            onMouseEnter={() => {
              setS4Animation(true);
            }}
            onMouseLeave={() => {
              setS4Animation(false);
            }}
          />
        </div>
        <Parallax
          speed={paralaxSpeed}
          className="section-desc home1__section4-right"
        >
          <motion.div
            className="image-desc"
            {...animDesc}
            animate={s4animation ? "hover" : "initial"}
            onMouseEnter={() => {
              setS4Animation(true);
            }}
            onMouseLeave={() => {
              setS4Animation(false);
            }}
          >
            <div className="line"></div>
            <h3>VIFON</h3>
            <p>Cieszy michę</p>
          </motion.div>
        </Parallax>
      </div>

      {/* SECTION 5 */}
      <div
        className="section home1__section5"
        onClick={() => {
          setCampaignData("tao-tao");
          setShowCampaign(true);
        }}
        id="tao-tao"
      >
        <div className="show-desktop home1__section5-left">
          <motion.img
            src={sosy}
            className="home1__section5-left-main"
            {...animImg}
            animate={s5animation ? "hover" : "initial"}
            onMouseEnter={() => {
              setS5Animation(true);
            }}
            onMouseLeave={() => {
              setS5Animation(false);
            }}
          />
          <Parallax
            speed={paralaxSpeed}
            className="home1__section5-left-circle"
          >
            <motion.img
              src={tao}
              className="color-shape"
              {...animShape}
              animate={s5animation ? "hover" : "initial"}
            />
          </Parallax>
        </div>
        <div className="section home1__section5-right">
          <Parallax
            speed={paralaxSpeed}
            className="section-header home1__section5-right-title"
          >
            <div className="header-index">
              <motion.h2
                {...animHeader}
                animate={s5animation ? "hover" : "initial"}
              >
                We are taste
              </motion.h2>
            </div>
          </Parallax>
          <div className="section-image show-mobile">
            <motion.img
              src={sosy}
              className="home1__section5-left-main"
              {...animImg}
              animate={s5animation ? "hover" : "initial"}
              onMouseEnter={() => {
                setS5Animation(true);
              }}
              onMouseLeave={() => {
                setS5Animation(false);
              }}
            />
          </div>
          <Parallax
            speed={paralaxSpeed}
            className="section-desc home1__section5-right-content"
          >
            <motion.div
              className="image-desc"
              {...animDesc}
              animate={s5animation ? "hover" : "initial"}
              onMouseEnter={() => {
                setS5Animation(true);
              }}
              onMouseLeave={() => {
                setS5Animation(false);
              }}
            >
              <div className="line"></div>
              <h3>Tao tao</h3>
              <p>Sekretne sosy Michela Morana</p>
            </motion.div>
          </Parallax>
        </div>
      </div>

      {/* SECTION 6 */}
      <div
        className="section home1__section6"
        whilehover="hover"
        animate="rest"
        onClick={() => {
          setCampaignData("arte");
          setShowCampaign(true);
        }}
        id="arte"
      >
        <Parallax
          speed={paralaxSpeed}
          className="section-desc home1__section6-left"
        >
          <motion.div
            className="image-desc_right"
            {...animDesc}
            animate={s6animation ? "hover" : "initial"}
            onMouseEnter={() => {
              setS6Animation(true);
            }}
            onMouseLeave={() => {
              setS6Animation(false);
            }}
          >
            <div className="line"></div>
            <h3>Arte</h3>
            <p>Rozwijamy pzed Tobą dywany</p>
          </motion.div>
        </Parallax>

        <div className="section-image home1__section6-right">
          <motion.img
            src={arte}
            {...animImg}
            animate={s6animation ? "hover" : "initial"}
            onMouseEnter={() => {
              setS6Animation(true);
            }}
            onMouseLeave={() => {
              setS6Animation(false);
            }}
          />
        </div>
      </div>
      {/* SECTION 7 */}
      <div
        className="section home1__section7"
        onClick={() => {
          setCampaignData("eurostyl");
          setShowCampaign(true);
        }}
        id="eurostyl"
      >
        <Parallax
          speed={paralaxSpeed}
          className="section-desc home1__section7-1"
        >
          <motion.div
            className="image-desc_right"
            {...animDesc}
            animate={s7animation ? "hover" : "initial"}
            onMouseEnter={() => {
              setS7Animation(true);
            }}
            onMouseLeave={() => {
              setS7Animation(false);
            }}
          >
            <div className="line"></div>
            <h3>Euro Styl</h3>
            <p>Doki</p>
          </motion.div>
        </Parallax>

        <div className="section-image home1__section7-2">
          <motion.img
            src={doki4}
            {...animImg}
            animate={s7animation ? "hover" : "initial"}
            onMouseEnter={() => {
              setS7Animation(true);
            }}
            onMouseLeave={() => {
              setS7Animation(false);
            }}
          />
        </div>
        <Parallax
          speed={paralaxSpeed}
          className="section-header home1__section7-3"
        >
          <div className="header-index">
            <motion.h2
              {...animHeader}
              animate={s6animation ? "hover" : "initial"}
            >
              WE ARE LEGEND
            </motion.h2>
          </div>
        </Parallax>
      </div>
      {/* SECTION 8 */}
      <div
        className="section home1__section8"
        onClick={() => {
          setCampaignData("wakacje-pl");
          setShowCampaign(true);
        }}
        id="wakacje-pl"
      >
        <div className="section home1__section8-left">
          <Parallax speed={paralaxSpeed} className="section-header">
            <div className="header-index">
              <motion.h2
                className="relax"
                {...animHeader}
                animate={s8animation ? "hover" : "initial"}
              >
                WE ARE RELAX
              </motion.h2>
            </div>
          </Parallax>
          <div className="section-image show-mobile image-container home1__section8-img">
            <motion.img
              src={wakacje4}
              {...animImg}
              animate={s8animation ? "hover" : "initial"}
              onMouseEnter={() => {
                setS8Animation(true);
              }}
              onMouseLeave={() => {
                setS8Animation(false);
              }}
            />
          </div>
          <Parallax
            speed={paralaxSpeed}
            className="section-desc home1__section8-bottom"
          >
            <motion.div
              className="image-desc_right"
              {...animDesc}
              animate={s8animation ? "hover" : "initial"}
              onMouseEnter={() => {
                setS8Animation(true);
              }}
              onMouseLeave={() => {
                setS8Animation(false);
              }}
            >
              <div className="line"></div>
              <h3>Wakacje.pl</h3>
              <p>Rankingi</p>
            </motion.div>
          </Parallax>
        </div>

        <div className="show-desktop image-container home1__section8-img">
          <motion.img
            src={wakacje4}
            {...animImg}
            animate={s8animation ? "hover" : "initial"}
            onMouseEnter={() => {
              setS8Animation(true);
            }}
            onMouseLeave={() => {
              setS8Animation(false);
            }}
          />
          <Parallax speed={paralaxSpeed} className="color-shape-container">
            <motion.img
              src={dot}
              className="color-shape"
              {...animShape}
              animate={s8animation ? "hover" : "initial"}
            />
          </Parallax>
        </div>
      </div>
      {/* SECTION 9 */}
      <div
        className="section home1__section9"
        onClick={() => {
          setCampaignData("vifon");
          setShowCampaign(true);
        }}
        id="vifon"
      >
        <div className="section-image home1__section9-left">
          <motion.img
            src={vifon}
            {...animImg}
            animate={s9animation ? "hover" : "initial"}
            onMouseEnter={() => {
              setS9Animation(true);
            }}
            onMouseLeave={() => {
              setS9Animation(false);
            }}
          />
        </div>
        <Parallax
          speed={paralaxSpeed}
          className="section-desc home1__section9-right"
        >
          <motion.div
            className="image-desc"
            {...animDesc}
            animate={s9animation ? "hover" : "initial"}
            onMouseEnter={() => {
              setS9Animation(true);
            }}
            onMouseLeave={() => {
              setS9Animation(false);
            }}
          >
            <div className="line"></div>
            <h3>Vifon</h3>
            <p>Kawa</p>
          </motion.div>
        </Parallax>
      </div>
      {/*  SECTION 10*/}
      <div
        className="section home1__section10"
        onClick={() => {
          setCampaignData("solaris-bus-stacja");
          setShowCampaign(true);
        }}
        id="wakacje-pl"
      >
        <div className="section home1__section10-left">
          <Parallax speed={paralaxSpeed} className="section-header">
            <div className="header-index">
              <motion.h2
                className="relax"
                {...animHeader}
                animate={s10animation ? "hover" : "initial"}
              >
                WE ARE YOUTUBE
              </motion.h2>
            </div>
          </Parallax>
          <div className="section-image show-mobile image-container home1__section10-img">
            <motion.img
              src={busstacja}
              {...animImg}
              animate={s10animation ? "hover" : "initial"}
              onMouseEnter={() => {
                setS10Animation(true);
              }}
              onMouseLeave={() => {
                setS10Animation(false);
              }}
            />
          </div>
          <Parallax
            speed={paralaxSpeed}
            className="section-desc home1__section10-bottom"
          >
            <motion.div
              className="image-desc_right"
              {...animDesc}
              animate={s10animation ? "hover" : "initial"}
              onMouseEnter={() => {
                setS10Animation(true);
              }}
              onMouseLeave={() => {
                setS10Animation(false);
              }}
            >
              <div className="line"></div>
              <h3>SOLARIS</h3>
              <p>Bus Stacja</p>
            </motion.div>
          </Parallax>
        </div>

        <div className="show-desktop image-container home1__section10-img">
          <motion.img
            src={busstacja}
            {...animImg}
            animate={s10animation ? "hover" : "initial"}
            onMouseEnter={() => {
              setS10Animation(true);
            }}
            onMouseLeave={() => {
              setS10Animation(false);
            }}
          />
          <Parallax speed={paralaxSpeed} className="color-shape-container">
            <motion.img
              src={dot}
              className="color-shape"
              {...animShape}
              animate={s9animation ? "hover" : "initial"}
            />
          </Parallax>
        </div>
      </div>
      {/*SECTION 11*/}
      <div
        className="section home1__section11"
        onClick={() => {
          setCampaignData("vifon-wystrzalowe-prazynki");
          setShowCampaign(true);
        }}
        id="vifon-wystrzalowe-prazynki"
      >
        <div className="col home1__section11-bottom">
          <div
            className=" section-image home1__section11-bottom-left"
            style={{ zIndex: "49" }}
          >
            <motion.img
              src={prazynki}
              {...animImg}
              animate={s11animation ? "hover" : "initial"}
              onMouseEnter={() => {
                setS11Animation(true);
              }}
              onMouseLeave={() => {
                setS11Animation(false);
              }}
            />
          </div>
          <Parallax
            speed={paralaxSpeed}
            className="section-desc home1__section11-bottom-right"
          >
            <motion.div
              className="image-desc"
              {...animDesc}
              animate={s11animation ? "hover" : "initial"}
              onMouseEnter={() => {
                setS11Animation(true);
              }}
              onMouseLeave={() => {
                setS11Animation(false);
              }}
            >
              <div className="line"></div>
              <h3>VIFON</h3>
              <p>Wystrzałowe prażynki</p>
            </motion.div>
          </Parallax>
        </div>
      </div>
      {/* Section 12 */}

      <div
        className="section home1__section12"
        whilehover="hover"
        animate="rest"
        onClick={() => {
          setCampaignData("avia");
          setShowCampaign(true);
        }}
        id="avia"
      >
        <div className="section home1__section12-left">
          <Parallax speed={paralaxSpeed} className="section-header">
            <div className="header-index">
              <motion.h2
                className="relax"
                {...animHeader}
                animate={s12animation ? "hover" : "initial"}
              >
                WE ARE DRIVE
              </motion.h2>
            </div>
          </Parallax>
          <div className="section-image show-mobile image-container home1__section12-img">
            <motion.img
              src={avia}
              {...animImg}
              animate={s12animation ? "hover" : "initial"}
              onMouseEnter={() => {
                setS12Animation(true);
              }}
              onMouseLeave={() => {
                setS12Animation(false);
              }}
            />
          </div>
          <Parallax
            speed={paralaxSpeed}
            className="section-desc home1__section12-bottom"
          >
            <motion.div
              className="image-desc_right"
              {...animDesc}
              animate={s12animation ? "hover" : "initial"}
              onMouseEnter={() => {
                setS12Animation(true);
              }}
              onMouseLeave={() => {
                setS12Animation(false);
              }}
            >
              <div className="line"></div>
              <h3>Avia</h3>
              <p>Płatności Spark</p>
            </motion.div>
          </Parallax>
        </div>

        <div className="show-desktop image-container home1__section12-img">
          <motion.img
            src={avia}
            {...animImg}
            animate={s12animation ? "hover" : "initial"}
            onMouseEnter={() => {
              setS12Animation(true);
            }}
            onMouseLeave={() => {
              setS12Animation(false);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Home1;

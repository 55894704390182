import React, { useState } from "react";
import { Link } from "gatsby";
import { animDesc, animHeader, animImg, animShape } from "./motions";
import { motion } from "framer-motion";
import { Parallax } from "react-scroll-parallax";
import { littleParalax } from "../components/paralaxas";
import "../styles/3.scss";
import hard from "../images/13hard.png";
import wisniewski from "../images/14wisniewski.png";
import solaris from "../images/15solaris.png";
import santiDiving from "../images/santi_diving.jpg";
import dixit from "../images/17_dixit.png";
import wakacje from "../images/18_wakacje.png";
import tao from "../images/19_tao_tao.png";
import makarony from "../images/makarony.png";
import circle from "../images/circle.svg";
const Home3 = ({ setShowCampaign, setCampaignData, paralaxSpeed }) => {
  const [s1animation, setS1Animation] = useState(false);
  const [s2animation, setS2Animation] = useState(false);
  const [s3animation, setS3Animation] = useState(false);
  const [s4animation, setS4Animation] = useState(false);
  const [s5animation, setS5Animation] = useState(false);
  const [s6animation, setS6Animation] = useState(false);
  const [s7animation, setS7Animation] = useState(false);
  const [s8animation, setS8Animation] = useState(false);
  return (
    <div className="home3">
      {/* Section 1 */}

      <div
        className="section home3__section1"
        onClick={() => {
          setCampaignData("tao-tao-2");
          setShowCampaign(true);
        }}
        id="tao-tao-2"
      >
        <Parallax
          speed={paralaxSpeed}
          className="section-desc home3__section1-left"
        >
          <motion.div
            className="image-desc_right"
            {...animDesc}
            animate={s1animation ? "hover" : "initial"}
            onMouseEnter={() => {
              setS1Animation(true);
            }}
            onMouseLeave={() => {
              setS1Animation(false);
            }}
          >
            <div className="line"></div>

            <h3>Tao Tao</h3>
            <p>Makarony bezglutenowe</p>
          </motion.div>
        </Parallax>
        <div className="section-image home3__section1-right">
          <motion.img
            src={makarony}
            {...animImg}
            animate={s1animation ? "hover" : "initial"}
            onMouseEnter={() => {
              setS1Animation(true);
            }}
            onMouseLeave={() => {
              setS1Animation(false);
            }}
          />
        </div>
      </div>
      {/* Section 2 */}
      <div
        className="section home3__section2"
        onClick={() => {
          setCampaignData("hard-rock-cafe");
          setShowCampaign(true);
        }}
        id="hard-rock-cafe"
      >
        <div className="show-desktop home3__section2-left">
          <motion.img
            className="home3__pic"
            src={hard}
            {...animImg}
            animate={s2animation ? "hover" : "initial"}
            onMouseEnter={() => {
              setS2Animation(true);
            }}
            onMouseLeave={() => {
              setS2Animation(false);
            }}
          />
          <Parallax speed={paralaxSpeed} className="circle-container">
            <motion.img
              className="home3__circle color-shape"
              src={circle}
              {...animShape}
              animate={s2animation ? "hover" : "initial"}
            />
          </Parallax>
        </div>
        <div className="home3__section2-right">
          <Parallax speed={paralaxSpeed} className="section-header ">
            <div className="header-index">
              <motion.h2
                {...animHeader}
                animate={s2animation ? "hover" : "initial"}
              >
                WE ARE ROCK
              </motion.h2>
            </div>
          </Parallax>
          <Parallax
            speed={paralaxSpeed}
            className="section-image show-mobile home3__section2-left"
          >
            <motion.img
              className="home3__pic"
              src={hard}
              {...animImg}
              animate={s2animation ? "hover" : "initial"}
              onMouseEnter={() => {
                setS2Animation(true);
              }}
              onMouseLeave={() => {
                setS2Animation(false);
              }}
            />
            <motion.img
              className="home3__circle color-shape"
              src={circle}
              {...animShape}
              animate={s2animation ? "hover" : "initial"}
            />
          </Parallax>
          <Parallax speed={paralaxSpeed} className="section-desc">
            <motion.div
              className="section-desc image-desc"
              {...animDesc}
              animate={s2animation ? "hover" : "initial"}
              onMouseEnter={() => {
                setS2Animation(true);
              }}
              onMouseLeave={() => {
                setS2Animation(false);
              }}
            >
              <div className="line"></div>
              <h3>Hard Rock Cafe</h3>
              <p>Sprawdź czy do siebie pasujemy</p>
            </motion.div>
          </Parallax>
        </div>
      </div>

      {/* Section 3 */}

      <div
        className="home3__section3 section "
        onClick={() => {
          setCampaignData("gstories");
          setShowCampaign(true);
        }}
        id="gstories"
      >
        <Parallax
          speed={paralaxSpeed}
          className="section-header home3__section3-left"
        >
          <div className="header-index">
            <motion.h2
              {...animHeader}
              animate={s3animation ? "hover" : "initial"}
            >
              WE ARE STORY
            </motion.h2>
          </div>
        </Parallax>
        <Parallax
          speed={paralaxSpeed}
          className="section-desc home3__section3-center"
        >
          <motion.div
            className="image-desc_right"
            {...animDesc}
            animate={s3animation ? "hover" : "initial"}
            onMouseEnter={() => {
              setS3Animation(true);
            }}
            onMouseLeave={() => {
              setS3Animation(false);
            }}
          >
            <div className="line"></div>
            <h3>#GStories</h3>
            <p>Janusz Wiśniewski</p>
            <p className="text-nowrap">Życie skrojone na miarę</p>
          </motion.div>
        </Parallax>
        <div className="section-image home3__section3-right">
          <motion.img
            src={wisniewski}
            {...animImg}
            animate={s3animation ? "hover" : "initial"}
            onMouseEnter={() => {
              setS3Animation(true);
            }}
            onMouseLeave={() => {
              setS3Animation(false);
            }}
          />
        </div>
      </div>

      {/* Section 4 */}

      <div
        className="section home3__section4"
        onClick={() => {
          setCampaignData("solaris");
          setShowCampaign(true);
        }}
        id="solaris"
      >
        <Parallax
          speed={paralaxSpeed}
          className="section-desc home3__section4-left"
        >
          <motion.div
            className="image-desc_right"
            {...animDesc}
            animate={s4animation ? "hover" : "initial"}
            onMouseEnter={() => {
              setS4Animation(true);
            }}
            onMouseLeave={() => {
              setS4Animation(false);
            }}
          >
            <div className="line"></div>
            <h3>Solaris</h3>
            <p>Urbino 15 LE</p>
          </motion.div>
        </Parallax>
        <div className="section-image home3__section4-right">
          <motion.img
            src={solaris}
            {...animImg}
            animate={s4animation ? "hover" : "initial"}
            onMouseEnter={() => {
              setS4Animation(true);
            }}
            onMouseLeave={() => {
              setS4Animation(false);
            }}
          />
          <Parallax speed={paralaxSpeed} className="circle-container">
            <motion.img
              className="circle__home4 color-shape"
              src={circle}
              {...animShape}
              animate={s4animation ? "hover" : "initial"}
            />
          </Parallax>
        </div>
      </div>

      {/* Section 5 */}

      <div
        className="section home3__section5"
        onClick={() => {
          setCampaignData("santi-diving");
          setShowCampaign(true);
        }}
        id="santi-diving"
      >
        <div className="section-image home3__section5-left">
          <motion.img
            src={santiDiving}
            {...animImg}
            animate={s5animation ? "hover" : "initial"}
            onMouseEnter={() => {
              setS5Animation(true);
            }}
            onMouseLeave={() => {
              setS5Animation(false);
            }}
          />
        </div>
        <Parallax
          speed={paralaxSpeed}
          className="section-desc home3__section5-right"
        >
          <motion.div
            className="image-desc"
            {...animDesc}
            animate={s5animation ? "hover" : "initial"}
            onMouseEnter={() => {
              setS5Animation(true);
            }}
            onMouseLeave={() => {
              setS5Animation(false);
            }}
          >
            <div className="line" />
            <h3>Santi Diving</h3>
            <p>We never fall back</p>
          </motion.div>
        </Parallax>
      </div>

      {/* Section 6 */}

      <div
        className="section home3__section6"
        onClick={() => {
          setCampaignData("dixit");
          setShowCampaign(true);
        }}
        id="dixit"
      >
        <div className="section home3__section6-left">
          <Parallax speed={paralaxSpeed} className="section-header">
            <div className="header-index">
              <motion.h2
                className="relax"
                {...animHeader}
                animate={s6animation ? "hover" : "initial"}
              >
                WE ARE FUN
              </motion.h2>
            </div>
          </Parallax>
          <div className="section-image show-mobile image-container home3__section6-img">
            <motion.img
              src={dixit}
              {...animImg}
              animate={s6animation ? "hover" : "initial"}
              onMouseEnter={() => {
                setS6Animation(true);
              }}
              onMouseLeave={() => {
                setS6Animation(false);
              }}
            />
          </div>
          <Parallax
            speed={paralaxSpeed}
            className="section-desc home3__section6-bottom"
          >
            <motion.div
              className="image-desc_right"
              {...animDesc}
              animate={s6animation ? "hover" : "initial"}
              onMouseEnter={() => {
                setS6Animation(true);
              }}
              onMouseLeave={() => {
                setS6Animation(false);
              }}
            >
              <div className="line"></div>
              <h3>REBEL</h3>
              <p>Dixit</p>
            </motion.div>
          </Parallax>
        </div>

        <div className="show-desktop image-container home1__section6-img">
          <motion.img
            src={dixit}
            {...animImg}
            animate={s6animation ? "hover" : "initial"}
            onMouseEnter={() => {
              setS6Animation(true);
            }}
            onMouseLeave={() => {
              setS6Animation(false);
            }}
          />
        </div>
      </div>

      {/* Section 7 */}

      <div
        className="section home3__section7"
        onClick={() => {
          setCampaignData("wakacje-pl-2");
          setShowCampaign(true);
        }}
        id="wakacje-pl-2"
      >
        <div className="section-image home3__section7-left">
          <motion.img
            src={wakacje}
            {...animImg}
            animate={s7animation ? "hover" : "initial"}
            onMouseEnter={() => {
              setS7Animation(true);
            }}
            onMouseLeave={() => {
              setS7Animation(false);
            }}
          />
        </div>
        <Parallax
          speed={paralaxSpeed}
          className="section-desc home3__section7-right"
        >
          <motion.div
            className="image-desc"
            {...animDesc}
            animate={s7animation ? "hover" : "initial"}
            onMouseEnter={() => {
              setS7Animation(true);
            }}
            onMouseLeave={() => {
              setS7Animation(false);
            }}
          >
            <div className="line"></div>
            <h3>Wakacje.pl</h3>
            <p>Stop motion</p>
          </motion.div>
        </Parallax>
      </div>

      {/* Section 8 */}

      <div
        className="section home3__section8"
        onClick={() => {
          setCampaignData("tao-tao-3");
          setShowCampaign(true);
        }}
        id="tao-tao-3"
      >
        <Parallax
          speed={paralaxSpeed}
          className="section-desc home3__section8-left"
        >
          <motion.div
            className="image-desc_right"
            {...animDesc}
            animate={s8animation ? "hover" : "initial"}
            onMouseEnter={() => {
              setS8Animation(true);
            }}
            onMouseLeave={() => {
              setS8Animation(false);
            }}
          >
            <div className="line"></div>
            <h3>Tao Tao</h3>
            <p>Łączy się ze smakiem</p>
          </motion.div>
        </Parallax>
        <div className="section-header section-image home3__section8-right">
          <div className="header-index">
            <Parallax speed={paralaxSpeed}>
              <motion.h2
                {...animHeader}
                animate={s8animation ? "hover" : "initial"}
              >
                WE ARE FAMILY
              </motion.h2>
            </Parallax>
          </div>
          <motion.img
            src={tao}
            {...animImg}
            animate={s8animation ? "hover" : "initial"}
            onMouseEnter={() => {
              setS8Animation(true);
            }}
            onMouseLeave={() => {
              setS8Animation(false);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Home3;

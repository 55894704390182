import React, { useEffect, useState } from "react";
import Vimeo from "@u-wave/react-vimeo";
import Video from "./video";

const Clickablevideo = ({
  vimeoid,
  title,
  subtitle,
  p,
  sectionSubtitle,
  centered = false,
  active = false,
  setShowCapaign,
  campaignData,
  videoParam,
  forceVideo,
}) => {
  const [videoActive, setVideoActive] = useState(false);
  const parsedSubtitle = subtitle
    ? subtitle
        .toLowerCase()
        .replace(/\s/g, "-")
        .replace("ł", "l")
        .replace("ó", "o")
        .replace("ę", "e")
        .replace("ą", "a")
        .replace("ś", "s")
        .replace("ń", "n")
        .replace("ć", "c")
        .replace("ź", "z")
        .replace("ż", "z")
    : 0;

  useEffect(() => {
    if (videoParam === parsedSubtitle) {
      setVideoActive(true);
    }
    if (forceVideo) {
      setVideoActive(active);
    }
    // TU SPRAWDZMY CZY W PATH JEST NASZ FILM
  }, []);
  return (
    <>
      {videoActive && (
        <Video
          vimeoid={vimeoid}
          onClose={() => {
            setVideoActive(false);
            if (setShowCapaign != undefined) {
              setShowCapaign(false);
            }
          }}
          sectionSubtitle={sectionSubtitle}
          title={title}
          subtitle={subtitle}
          p={p}
          parsedSubtitle={parsedSubtitle}
          campaignData={campaignData}
          forceVideo={forceVideo}
        />
      )}
      <div
        onClick={() => {
          setVideoActive(true);
        }}
        className={`wrap ${centered ? "player-centered" : ""}`}
      >
        <div
          style={{
            pointerEvents: "none",
            width: "100%",
          }}
        >
          <Vimeo video={vimeoid} controls={false} responsive={true} />
          <div className="activate-button-wrapper">
            <span className="activate-button" />
          </div>
        </div>
      </div>
    </>
  );
};
export default Clickablevideo;

import React, { useEffect } from "react";
import ProjectPage from "./projectPage";
import ProjectSection from "./projectSection";
import { useLocation } from "@reach/router";
import PreventScroll from "./preventScroll";
import Clickablevideo from "./clickablevideo";
import campaigns from "../assets/data/campaigns";
const ModalCampaign = ({ setShowCapaign, campaignData, videoParam }) => {
  const location = useLocation();
  useEffect(PreventScroll, []);
  useEffect(() => {
    if (!campaigns[campaignData].forceVideo) {
      window.history.replaceState(null, null, `/wearemotion/${campaignData}`);
      return () => {
        window.history.replaceState(null, null, `/wearemotion`);
      };
    }
  }, []);

  useEffect(() => {
    if (!window.location.href.includes(campaignData)) {
      setShowCapaign(false);
    }
    return () => {
      window.history.replaceState(null, null, `/wearemotion`);
    };
  }, [location]);

  return !campaigns[campaignData].forceVideo ? (
    <ProjectPage
      title={campaigns[campaignData].title}
      subtitle={campaigns[campaignData].subtitle}
      setShowCapaign={setShowCapaign}
    >
      {campaigns[campaignData].videos.map((item, index) => {
        return (
          <ProjectSection
            id={item.id}
            title={campaigns[campaignData].title}
            subtitle={item.subtitle}
            sectionSubtitle={campaigns[campaignData].subtitle}
            p={campaigns[campaignData].p}
            right={index % 2 != 0 ? true : false}
            key={index}
            campaignData={campaignData}
            videoParam={videoParam}
          />
        );
      })}
    </ProjectPage>
  ) : (
    <Clickablevideo
      vimeoid={campaigns[campaignData].videos[0].id}
      title={campaigns[campaignData].title}
      sectionSubtitle={campaigns[campaignData].subtitle}
      subtitle={campaigns[campaignData].videos[0].subtitle}
      p={campaigns[campaignData].p}
      forceVideo={campaigns[campaignData].forceVideo}
      active={true}
      setShowCapaign={setShowCapaign}
    />
  );
};

export default ModalCampaign;

import React, { useState } from "react";
import { Link } from "gatsby";
import { animImg, animShape, animDesc, animHeader } from "./motions";
import { motion } from "framer-motion";
import { Parallax } from "react-scroll-parallax";
import { littleParalax } from "../components/paralaxas";
import vetrex from "../images/7_vetrex.png";
import eurostyl from "../images/8_EUROSTYL_EKSPRESJA.png";
import eurostyl2 from "../images/9_eurostyl.png";
import immergas from "../images/10_immergas.png";
import vastint from "../images/11_vastint.png";
import przybysz from "../images/12_przybysz.png";
import "../styles/2.scss";
import vas from "../images/vas.svg";

const Home2 = ({ setShowCampaign, setCampaignData, paralaxSpeed }) => {
  const [s1animation, setS1Animation] = useState(false);
  const [s2animation, setS2Animation] = useState(false);
  const [s3animation, setS3Animation] = useState(false);
  const [s4animation, setS4Animation] = useState(false);
  const [s5animation, setS5Animation] = useState(false);
  const [s6animation, setS6Animation] = useState(false);

  return (
    <div className="home2">
      {/* Section 1 */}
      <div
        className="section home2__section1"
        onClick={() => {
          setCampaignData("vetrex");
          setShowCampaign(true);
        }}
        id="vetrex"
      >
        <div className="section-image home2__section1-left">
          <motion.img
            src={vetrex}
            {...animImg}
            animate={s1animation ? "hover" : "initial"}
            onMouseEnter={() => {
              setS1Animation(true);
            }}
            onMouseLeave={() => {
              setS1Animation(false);
            }}
          />
        </div>
        <Parallax
          speed={paralaxSpeed}
          className="section-desc home2__section1-right"
        >
          <motion.div
            className="image-desc"
            {...animDesc}
            animate={s1animation ? "hover" : "initial"}
            onMouseEnter={() => {
              setS1Animation(true);
            }}
            onMouseLeave={() => {
              setS1Animation(false);
            }}
          >
            <div className="line"></div>
            <h3>Vetrex</h3>
            <p>LUM'UP</p>
          </motion.div>
        </Parallax>
      </div>

      {/* Section 2 */}

      <div
        className="section home2__section2"
        onClick={() => {
          setCampaignData("eurostyl-2");
          setShowCampaign(true);
        }}
        id="eurostyl-2"
      >
        <div style={{ zIndex: "50", position: "relative" }}>
          <div className="header-index">
            <Parallax
              speed={paralaxSpeed}
              className="section-header home2__section2-top"
            >
              <motion.h2
                {...animHeader}
                animate={s2animation ? "hover" : "initial"}
              >
                WE ARE EXPRESSION
              </motion.h2>
            </Parallax>
          </div>
        </div>
        <div className="col home2__section2-bottom">
          <div
            className=" section-image home2__section2-bottom-left"
            style={{ zIndex: "49" }}
          >
            <motion.img
              src={eurostyl}
              {...animImg}
              animate={s2animation ? "hover" : "initial"}
              onMouseEnter={() => {
                setS2Animation(true);
              }}
              onMouseLeave={() => {
                setS2Animation(false);
              }}
            />
          </div>
          <Parallax
            speed={paralaxSpeed}
            className="section-desc home2__section2-bottom-right"
          >
            <motion.div
              className="image-desc"
              {...animDesc}
              animate={s2animation ? "hover" : "initial"}
              onMouseEnter={() => {
                setS2Animation(true);
              }}
              onMouseLeave={() => {
                setS2Animation(false);
              }}
            >
              <div className="line"></div>
              <h3>Euro Styl</h3>
              <p>Ekspresja</p>
            </motion.div>
          </Parallax>
        </div>
      </div>

      {/* Section 3 */}

      <div
        className="section home2__section3"
        onClick={() => {
          setCampaignData("eurostyl-3");
          setShowCampaign(true);
        }}
        id="eurostyl-3"
      >
        <div className="section-image home2__section3-left">
          <motion.img
            src={eurostyl2}
            {...animImg}
            animate={s3animation ? "hover" : "initial"}
            onMouseEnter={() => {
              setS3Animation(true);
            }}
            onMouseLeave={() => {
              setS3Animation(false);
            }}
          />
        </div>
        <Parallax
          speed={paralaxSpeed}
          className="section-desc home2__section3-right"
        >
          <motion.div
            className="image-desc"
            {...animDesc}
            animate={s3animation ? "hover" : "initial"}
            onMouseEnter={() => {
              setS3Animation(true);
            }}
            onMouseLeave={() => {
              setS3Animation(false);
            }}
          >
            <div className="line"></div>
            <h3>Euro Styl</h3>
            <p>Dawna Poczta</p>
          </motion.div>
        </Parallax>
      </div>

      {/* Section 4 */}

      <div
        className="section home2__section4"
        onClick={() => {
          setCampaignData("immergas");
          setShowCampaign(true);
        }}
        id="immergas"
      >
        <div className="section home2__section4-left">
          <Parallax
            speed={paralaxSpeed}
            className="section-header home2__section4-left-top"
          >
            <div className="header-index">
              <motion.h2
                {...animHeader}
                animate={s4animation ? "hover" : "initial"}
              >
                WE ARE TECHNOLOGY
              </motion.h2>
            </div>
          </Parallax>
          <div className="section-image show-mobile home2__section4-right">
            <motion.img
              src={immergas}
              {...animImg}
              animate={s4animation ? "hover" : "initial"}
              onMouseEnter={() => {
                setS4Animation(true);
              }}
              onMouseLeave={() => {
                setS4Animation(false);
              }}
            />
          </div>
          <Parallax
            speed={paralaxSpeed}
            className="section-desc home2__section4-left-bottom"
          >
            <motion.div
              className="image-desc_right"
              {...animDesc}
              animate={s4animation ? "hover" : "initial"}
              onMouseEnter={() => {
                setS4Animation(true);
              }}
              onMouseLeave={() => {
                setS4Animation(false);
              }}
            >
              <div className="line"></div>
              <h3>immergas</h3>
              <p>Oddział R32</p>
            </motion.div>
          </Parallax>
        </div>

        <div className="show-desktop home2__section4-right">
          <motion.img
            src={immergas}
            {...animImg}
            animate={s4animation ? "hover" : "initial"}
            onMouseEnter={() => {
              setS4Animation(true);
            }}
            onMouseLeave={() => {
              setS4Animation(false);
            }}
          />
        </div>
      </div>

      {/* Section 5 */}

      <div
        className="section home2__section5"
        onClick={() => {
          setCampaignData("vastint");
          setShowCampaign(true);
        }}
        id="vastint"
      >
        <Parallax
          speed={paralaxSpeed}
          className="section-desc home2__section5-left"
        >
          <motion.div
            className="image-desc_right"
            {...animDesc}
            animate={s5animation ? "hover" : "initial"}
            onMouseEnter={() => {
              setS5Animation(true);
            }}
            onMouseLeave={() => {
              setS5Animation(false);
            }}
          >
            <div className="line"></div>
            <h3>Vastint</h3>
            <p>Riverview</p>
          </motion.div>
        </Parallax>
        <div className="section-image home2__section5-right">
          <motion.img
            src={vastint}
            {...animImg}
            animate={s5animation ? "hover" : "initial"}
            onMouseEnter={() => {
              setS5Animation(true);
            }}
            onMouseLeave={() => {
              setS5Animation(false);
            }}
          />
          <Parallax speed={paralaxSpeed} className="img-vas-container">
            <motion.img
              className="img-vas color-shape"
              src={vas}
              {...animShape}
              animate={s5animation ? "hover" : "initial"}
            />
          </Parallax>
        </div>
      </div>

      {/* Section 6 */}

      <div
        className="section home2__section6"
        onClick={() => {
          setCampaignData("paulina-przybysz");
          setShowCampaign(true);
        }}
        id="paulina-przybysz"
      >
        <div className="section-image home2__section6-left">
          <motion.img
            src={przybysz}
            {...animImg}
            animate={s6animation ? "hover" : "initial"}
            onMouseEnter={() => {
              setS6Animation(true);
            }}
            onMouseLeave={() => {
              setS6Animation(false);
            }}
          />
        </div>
        <Parallax
          speed={paralaxSpeed}
          className="section-header home2__section6-up"
        >
          <div className="header-index">
            <motion.h2
              {...animHeader}
              animate={s6animation ? "hover" : "initial"}
            >
              WE ARE MUSIC
            </motion.h2>
          </div>
        </Parallax>
        <Parallax
          speed={paralaxSpeed}
          className="section-desc home2__section6-right"
        >
          <motion.div
            className="image-desc"
            {...animDesc}
            animate={s6animation ? "hover" : "initial"}
            onMouseEnter={() => {
              setS6Animation(true);
            }}
            onMouseLeave={() => {
              setS6Animation(false);
            }}
          >
            <div className="line"></div>
            <h3>Paulina Przybysz</h3>
            <p>Pirx</p>
          </motion.div>
        </Parallax>
      </div>
    </div>
  );
};

export default Home2;

const campaings = {
  mastercard: {
    title: "Mastercard",
    page: 1,
    subtitle: "Spełnienie",
    videos: [
      {
        id: "221276453",
        subtitle: "!spelnienie",
      },
    ],
  },
  "mitsubishi-electric": {
    title: "Mitsubishi Electric",
    page: 1,
    subtitle: "Melf",
    videos: [
      {
        id: "819436478",
        subtitle: "!Melf",
      },
    ],
  },
  "vifon-cieszy-miche": {
    title: "Vifon",
    page: 1,
    subtitle: "Cieszy michę",
    videos: [
      {
        id: "819833740",
        subtitle: "!Cieszy michę",
      },
    ],
  },
  "tao-tao": {
    title: "Tao Tao",
    page: 1,
    subtitle: "Sekretne sosy Michela Morana",
    videos: [
      {
        id: "https://vimeo.com/643476573/5eaac1b242",
        subtitle: "Sekretne sosy Michela Morana",
      },
      {
        id: "https://vimeo.com/643476375/020072061c",
        subtitle: "Kurczaczek niebylejaki",
      },
      {
        id: "https://vimeo.com/643476424/900ba52289",
        subtitle: "Czary z curry",
      },
      {
        id: "https://vimeo.com/643476447/53686af4ab",
        subtitle: "Umocz szaszłyka",
      },
      {
        id: "https://vimeo.com/643476482/e91eb9db73",
        subtitle: "W kuchni nie ma zasad",
      },
      {
        id: "543049084",
        subtitle: "Słodko pikantny sos chilli – billboard sponsorski",
      },
    ],
  },
  betclic: {
    title: "Betclic",
    page: 1,
    subtitle: "Wybierz swój kurs",
    videos: [
      {
        id: "819490766",
        subtitle: "Looney",
      },
      {
        id: "819493386",
        subtitle: "Sonya",
      },
      {
        id: "https://vimeo.com/819455037/b5fe93c9d2",
        subtitle: "Lipek",
      },
    ],
  },
  arte: {
    title: "Arte",
    subtitle: "Rozwijamy przed Tobą dywany",
    page: 1,
    videos: [
      {
        id: "639001656",
        subtitle: "Rozwijamy przed Tobą dywany",
      },
      {
        id: "https://vimeo.com/639002863/e5c07faa6b",
        subtitle: "Dywany najlepiej wybiera się w Arte",
      },
    ],
  },
  eurostyl: {
    title: "EURO STYL",
    subtitle: "Doki",
    page: 1,
    videos: [
      {
        id: "452598970",
        subtitle: "!doki",
      },
    ],
  },
  "wakacje-pl": {
    title: "Wakacje.pl",
    page: 1,
    subtitle: "Rankingi",
    videos: [
      {
        id: "149133358",
        subtitle: "Wakacje z dziećmi",
      },
      {
        id: "149133559",
        subtitle: "Egzotyka",
      },
      {
        id: "149133491",
        subtitle: "Last minute",
      },
      {
        id: "149133605",
        subtitle: "Narty",
      },
      {
        id: "149133913",
        subtitle: "Zwiedzanie",
      },
      {
        id: "202923668",
        subtitle: "Wakacje z dziećmi - billboard sponsorski",
      },
    ],
  },
  vifon: {
    title: "Vifon",
    subtitle: "Kawa",
    page: 1,
    videos: [
      {
        id: "543656571",
        subtitle: "!kawa",
      },
    ],
  },
  "solaris-bus-stacja": {
    title: "Solaris",
    page: 3,
    subtitle: "Bus Stacja",
    videos: [
      {
        id: "692233491",
        subtitle: "Klocki hamulcowe",
      },
      {
        id: "692234725",
        subtitle: "Przeguby",
      },
      {
        id: "692235674",
        subtitle: "Miechy",
      },
      {
        id: "692236823",
        subtitle: "Filtry klimatyzacji",
      },
      {
        id: "692237826",
        subtitle: "Filtry silnikowe",
      },
      {
        id: "692239570",
        subtitle: "Inne filtry",
      },
    ],
  },
  "vifon-wystrzalowe-prazynki": {
    title: "Vifon",
    page: 1,
    subtitle: "Wystrzałowe prażynki",
    videos: [
      {
        id: "692231289",
        subtitle: "!Wystrzałowe prażynki",
      },
    ],
  },
  avia: {
    title: "Avia",
    page: 1,
    subtitle: "Płatności Spark",
    videos: [
      {
        id: "819426876",
        subtitle: "!Płatności Spark",
      },
    ],
  },
  vetrex: {
    title: "Vetrex",
    subtitle: "LUM’UP",
    page: 2,
    videos: [
      {
        id: "383994426",
        subtitle: "!lumup",
      },
    ],
  },
  "eurostyl-2": {
    title: "EURO STYL",
    subtitle: "Ekspresja",
    page: 2,
    videos: [
      {
        id: "238032433",
        subtitle: "!ekspresja",
      },
    ],
  },
  "eurostyl-3": {
    title: "EURO STYL",
    page: 2,
    subtitle: "Dawna Poczta",
    videos: [
      {
        id: "352006652",
        subtitle: "Maniek",
      },
      {
        id: "352008030",
        subtitle: "Sławek",
      },
      {
        id: "352009245",
        subtitle: "Wojtek",
      },
    ],
  },
  immergas: {
    title: "Immergas",
    subtitle: "Oddział R32",
    page: 2,
    videos: [
      {
        id: "643530984",
        subtitle: "!oddzial-r32",
      },
    ],
  },
  vastint: {
    title: "Vastint",
    page: 2,
    subtitle: "Riverview",
    videos: [
      {
        id: "383847784",
        subtitle: "Architektura",
      },
      {
        id: "383847985",
        subtitle: "Fit-out",
      },
      {
        id: "383848280",
        subtitle: "Rzeźba",
      },
    ],
  },
  "paulina-przybysz": {
    title: "Paulina Przybysz",
    subtitle: "Pirx",
    page: 2,
    videos: [
      {
        id: "238376185",
        subtitle: "!pirx",
      },
    ],
  },
  "tao-tao-2": {
    title: "Tao Tao",
    page: 3,
    subtitle: "Makarony bezglutenowe",
    videos: [
      {
        id: "https://vimeo.com/643476281/d16cf1d1dd",
        subtitle: "!makarony-bezglutenowe",
      },
    ],
  },
  "hard-rock-cafe": {
    title: "Hard Rock Cafe",
    page: 3,
    subtitle: "Sprawdź czy do siebie pasujemy",
    videos: [
      {
        id: "249505140",
        subtitle: "Ona",
      },
      {
        id: "249504904",
        subtitle: "On",
      },
    ],
  },
  gstories: {
    title: "#GStories",
    page: 3,
    subtitle: "Janusz Wiśniewski - Życie skrojone na miarę",
    videos: [
      {
        id: "296863938",
        subtitle: "!jw-zycie-skrojone-na-miare",
      },
    ],
  },
  solaris: {
    title: "Solaris",
    subtitle: "Urbino 15 LE",
    page: 3,
    videos: [
      {
        id: "543058958",
        subtitle: "!urbino-15-le",
      },
    ],
  },
  "santi-diving": {
    title: "Santi Diving",
    subtitle: "We never fall back",
    page: 3,
    videos: [
      {
        id: "819427379",
        subtitle: "!Santi diving",
      },
    ],
  },
  dixit: {
    title: "Rebel",
    subtitle: "Dixit",
    page: 3,
    videos: [
      {
        id: "166950750",
        subtitle: "!dixit",
      },
    ],
  },
  "wakacje-pl-2": {
    title: "Wakacje.pl",
    page: 3,
    subtitle: "Stop motion",
    videos: [
      {
        id: "199000884",
        subtitle: "Rodzice",
      },
      {
        id: "199000343",
        subtitle: "Dzieci",
      },
      {
        id: "198999531",
        subtitle: "Dziadkowie",
      },
    ],
  },
  "tao-tao-3": {
    title: "Tao Tao",
    page: 3,
    subtitle: "Łączy się ze smakiem",
    videos: [
      {
        id: "543065298",
        subtitle: "Grill",
      },
      {
        id: "543064895",
        subtitle: "Śniadanie",
      },
    ],
  },
  "hero-video": {
    title: "WE ARE",
    subtitle: "G58",
    forceVideo: true,
    videos: [
      {
        id: "452601027",
        subtitle: "",
      },
    ],
  },
};

export default campaings;

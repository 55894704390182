import React, {useEffect, useState, useRef} from 'react';
import Layout from '../components/layout';
import ModalCampaign from '../components/modalCampaign';
import Container from '../components/container';
import '../styles/main.scss';
import '../styles/home.scss';
import '../styles/button.scss';
import line from '../images/line.svg';
import ThisIs from '../images/ThisIs.svg';
import logotype from '../images/logotype.svg';
import Home1 from '../components/home1.js';
import Home2 from '../components/home2.js';
import Home3 from '../components/home3.js';
import campaigns from '../assets/data/campaigns';
import heroVideoDesktop from '../videos/weareg58-desktop.mp4';
import heroVideoMobile from '../videos/weareg58-mobile.mp4';
import {Helmet} from 'react-helmet';
const IndexPage = ({location, params}) => {
  const [showCampaign, setShowCampaign] = useState(false);
  const [campaignData, setCampaignData] = useState({});
  const [paralaxSpeed, setParalaxSpeed] = useState(1);
  const [counter, setCounter] = useState(0);
  const myRef = useRef(null);

  useEffect(() => {
    if (params && params.project && campaigns[params.project]) {
      // skrolujemy do dziada;
      setCampaignData(params.project);
      setShowCampaign(true);

      setCounter(campaigns[params.project].page - 1);
      const el = document.getElementById(params.project);
      if (el) {
        el.scrollIntoView();
      }
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        // const menuElement = document.getElementsByClassName("navbar__wrapper__links");
        setParalaxSpeed(10);
      } else {
        setParalaxSpeed(2);
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);
  }, []);

  return (
    <>
      <Helmet>
        <title>G58 - Strona główna</title>
        <meta
          name="keywords"
          content="produkcja filmowa,
            dom produkcyjny,
            film promocyjny,
            produkcja video,
            produkcja filmowa i telewizyjna,
            produkcja filmowa Gdańsk,
            dom produkcyjny Gdańsk,
            film promocyjny Gdańsk,
            produkcja video Gdańsk,
            produkcja filmowa i telewizyjna Gdańsk,
            produkcja filmowa Gdynia,
            dom produkcyjny Gdynia,
            film promocyjny Gdynia,
            produkcja video Gdynia,
            produkcja filmowa i telewizyjna Gdynia,
            produkcja filmowa Trójmiasto,
            dom produkcyjny Trójmiasto,
            film promocyjny Trójmiasto,
            produkcja video Trójmiasto,
            produkcja filmowa i telewizyjna Trójmiasto"
        />
      </Helmet>
      <Layout>
        <div className="home__section1">
          <video autoPlay muted loop playsInline className="show-mobile">
            <source src={heroVideoMobile} type="video/mp4" />
          </video>
          <video autoPlay muted loop playsInline className="show-desktop">
            <source src={heroVideoDesktop} type="video/mp4" />
          </video>
          {/* <div
          className="home__section1__description"
          onClick={() => {
            setCampaignData('hero-video');
            setShowCampaign(true);
          }}>
          <h2>Check #gstyle</h2>
          <p><img src={littlePlay} />Obejrzyj pełne video</p>
        </div> */}
        </div>
        <Container>
          <div className="home__section2" ref={myRef}>
            <div className="home__section2-left">
              <img src={ThisIs} />
            </div>
            <div className="home__section2-right">
              <img src={logotype} />
              <img
                src={line}
                style={{
                  objectPosition: 'left',
                  height: '10px',
                }}
              />
              <p>
                Jesteśmy trójmiejskim, kreatywnym domem produkcyjnym,
                specjalizującym się w tworzeniu spotów reklamowych. Realizujemy
                również różnego rodzaju kontent video od teledysków po filmy
                dokumentalne oraz sesje zdjęciowe i animacje.
              </p>
            </div>
          </div>
          <Home1
            paralaxSpeed={paralaxSpeed}
            setShowCampaign={setShowCampaign}
            setCampaignData={setCampaignData}
          />
          <Home2
            paralaxSpeed={paralaxSpeed}
            setShowCampaign={setShowCampaign}
            setCampaignData={setCampaignData}
          />
          <Home3
            paralaxSpeed={paralaxSpeed}
            setShowCampaign={setShowCampaign}
            setCampaignData={setCampaignData}
          />
          {showCampaign && (
            <ModalCampaign
              campaignData={campaignData}
              setShowCapaign={setShowCampaign}
              videoParam={params && params.video ? params.video : 0}
            />
          )}
          {/* <img src={img1} onClick={ () => {showVimeo(urls1, names1)}}/>
            {show && <Component urls={urls} names={names} hide={hideVimeo} /> } */}
        </Container>
      </Layout>
    </>
  );
};

export default IndexPage;

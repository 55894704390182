import React from "react";
import Layout from "./layout";
import { navigate } from "gatsby";
import { Turn as Hamburger } from "hamburger-react";
import "../styles/page.scss";

const ProjectPage = ({
  title,
  subtitle,
  description,
  children,
  backById,
  setShowCapaign,
}) => {
  return (
    <div className="modal">
      <div className="modal__fix">
        <div className="modal__wrapper">
          <div className="modal__close-button"></div>
          <div className="modal__desc-container">
            <div className="modal__head-container">
              <h1 className="modal__title">{title}</h1>
              <button
                className="close"
                onClick={() => setShowCapaign(false)}
              ></button>
            </div>
            <div className="modal__subdesc-container">
              <p className="modal__subtitle">{subtitle}</p>
              <p className="modal__subtitle2">{description}</p>
            </div>
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};

export default ProjectPage;

import React, { useState, useEffect, useRef } from "react";
import "../styles/fullscreenPlayer.scss";
import { ErrorBoundary } from "react-error-boundary";
import Vimeo from "@u-wave/react-vimeo";
import PreventScroll from "./preventScroll";
import PlayerNavigation from "./playerNavigation";
const Video = ({
  onClose,
  vimeoid,
  title,
  subtitle,
  parsedSubtitle,
  p,
  setShowCapaign,
  sectionSubtitle,
  campaignData,
  forceVideo,
}) => {
  const [playing, setPlaying] = useState(false);
  const [slide, setSlide] = useState(0);
  const [time, setTime] = useState(0);
  const [hiddenNavigation, setHiddenNavigation] = useState(true);
  const [fullscreen, setFullscreen] = useState(false);
  const [videoLoading, setVideLoading] = useState(true);
  const [player, setPlayer] = useState({});
  const [muted, setMuted] = useState(false);
  const [volume, setVolume] = useState(1);
  const [videoDuration, setVideoDuration] = useState(0);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (forceVideo) {
      PreventScroll();
    }
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (playing) {
        setHiddenNavigation(true);
      }
    }, 3000);
    if (campaignData) {
      window.history.replaceState(
        null,
        null,
        `/wearemotion/${campaignData}/${parsedSubtitle}`
      );
      return () => {
        clearTimeout(timer);
        window.history.replaceState(null, null, `/wearemotion/${campaignData}`);
      };
    }
  }, [playing, hiddenNavigation]);

  useEffect(() => {
    const timerInitial = setTimeout(() => {
      setPlaying(true);
      // setHiddenNavigation(false);
    }, 1200);
    return () => {
      clearTimeout(timerInitial);
    };
  }, []);

  // useEffect(() => {
  //   const timerMuted = setTimeout(() => {
  //     setMuted(false);
  //     setVolume(1);
  //     console.log("HEBBBB");
  //   }, 3000);
  //   return () => {
  //     clearTimeout(timerMuted);
  //   };
  // }, [playing]);

  return (
    <>
      <div>
        <div className={`player-active ${fullscreen ? "fs" : "nfs"}`}>
          <div className="player" id="player">
            <div className="player__nav">
              {!hiddenNavigation ? (
                <>
                  <div className="player__nav__upper">
                    <div className="player__nav__upper__details">
                      <h2>{title}</h2>
                      <h2>{sectionSubtitle}</h2>
                      <p>
                        {subtitle && subtitle.charAt(0) != "!" ? subtitle : ""}
                      </p>
                      <>
                        {p &&
                          p.map((item, key) => {
                            return <p key={key}>{item}</p>;
                          })}
                      </>
                    </div>
                    <button className="close" onClick={onClose} />
                  </div>
                  <div className="player__nav__mid">
                    {videoLoading ? (
                      <></>
                    ) : (
                      <button
                        className={`state-${playing ? "pause" : "play"}`}
                        onClick={() => setPlaying(!playing)}
                      ></button>
                    )}
                  </div>
                  <PlayerNavigation
                    setPlaying={setPlaying}
                    playing={playing}
                    setSlide={setSlide}
                    slide={slide}
                    videoDuration={videoDuration}
                    player={player}
                    setMuted={setMuted}
                    muted={muted}
                    setVolume={setVolume}
                    volume={volume}
                    fullscreen={fullscreen}
                    setFullscreen={setFullscreen}
                    hideNavButtons={false}
                  />
                  {/* NAV BOTTOM */}
                </>
              ) : (
                <div
                  className="player__nav__hidden"
                  onClick={() => {
                    setHiddenNavigation(false);
                  }}
                  onMouseMove={() => {
                    setHiddenNavigation(false);
                  }}
                />
              )}
            </div>
            <Vimeo
              controls={false}
              paused={!playing}
              autopause={false}
              autoplay
              showByline={false}
              video={vimeoid}
              start={time}
              muted={muted}
              volume={volume}
              width="100%"
              height="100%"
              className={`video ${loading ? "video-blur" : ""}`}
              onTimeUpdate={(e) => {
                setSlide(e.percent * 100);
                if (loading) {
                  setLoading(false);
                }
              }}
              onLoaded={(e) => {
                setVideLoading(false);
                setPlaying(true);
              }}
              onReady={(playerx, dis, a) => {
                playerx.getDuration().then((item) => {
                  setVideoDuration(item);
                });
                setPlayer(playerx);
              }}
              onPause={() => {
                if (slide === 0 && volume === 1 && !muted) {
                  setVolume(0);
                  setMuted(true);
                  setPlaying(false);
                  const timerInitial = setTimeout(() => {
                    setPlaying(true);
                    console.log("Próba uruchomienia video bez dzwięku");
                  }, 1000);
                }
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default Video;

import React, { useEffect } from "react";
import Clickablevideo from "./clickablevideo";

const ProjectSection = ({
  id,
  title,
  subtitle,
  p,
  right,
  sectionSubtitle,
  campaignData,
  videoParam,
}) => {
  return (
    <div className={`project__wrapper ${right ? "right" : "left"}`}>
      <p className="project__title wrap">
        {subtitle && subtitle.charAt(0) != "!" ? subtitle : ""}
      </p>
      <Clickablevideo
        vimeoid={id}
        title={title}
        subtitle={subtitle}
        p={p}
        sectionSubtitle={sectionSubtitle}
        campaignData={campaignData}
        videoParam={videoParam}
      />
    </div>
  );
};

export default ProjectSection;
